import Login from 'components/Login';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';

const LoginPage = () => {
  return <Login />;
};

export async function getStaticProps({ locale, }) {
  return {
    props: {
      ...await serverSideTranslations(locale, ['common',]),
    },
  };
}

export default LoginPage;