import { FormValidationRules, Form, initForm, validateForm, MessageType, ValidationMessage } from 'ts-form-validation';
import validator from 'validator';
import { useState, useCallback } from 'react';
import { TextFieldProps, TextField, Button, FormControl, Paper, FormHelperText, Theme, CircularProgress } from '@material-ui/core';
import styled from '@emotion/styled';
import { firebaseApp } from 'schema';
import { useRouter } from 'next/router';
import { NavigationPath } from '@shared/schema';
import { useTheme } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';

const SignInForm = styled(FormControl)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
});

const SignInButton = styled(Button)`
  margin-top: 15px;
  background-color: #0b70b4;
  color: #fff;
  font-weight: bold;

  &:hover {
    background-color: #0d84d4;
  }
`;

const StyledPaper = styled(Paper)`
  padding: 16px; 
  text-align: center;
`;

const ErrorMessage = styled(FormHelperText)`
  margin-top: 10px;
  margin-bottom: 5px;
`;

const StyledLogo = styled.img`
  width: 300px;
  padding: 16px;
`;

const Center = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  max-width: 100%;
  transform: translate(-50%, -50%);
`;

interface LoginForm {
  email: string;
  password: string;
}

const getRules = (): FormValidationRules<LoginForm> => {
  const rules: FormValidationRules<LoginForm> = {
    fields: {
      email: {
        required: true,
        requiredText: () => 'Required field',
        validate: (value?: string) => validator.isEmail(value) || {
          type: MessageType.ERROR,
          message: 'Invalid email',
        },
      },
      password: {
        required: true,
        requiredText: () => 'Required field',
        trim: true,
      },
    },
    validateForm: form => {
      const messages = {};
      return { ...form, messages, };
    },
  };
  return rules;
};

export const SignIn = () => {
  const { t, } = useTranslation();
  const theme = useTheme<Theme>();
  const router = useRouter();
  const [isLoading, setIsLoading,] = useState<boolean>(false);
  const [error, setError,] = useState<string | undefined>();
  const [form, setForm,] = useState<Form<LoginForm>>(
    initForm<LoginForm>(
      {
        email: '',
        password: '',
      },
      getRules()
    )
  );

  const handleTextInputOnChange = useCallback((key: keyof LoginForm) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setError(undefined);
    setForm(validateForm(
      {
        ...form,
        values: {
          ...form.values,
          [key]: event.currentTarget.value,
        },
      },
      {
        usePreprocessor: false,
      }
    ));
  }, [form,]);

  const handleTextInputOnBlur = useCallback((key: keyof LoginForm) => () => {
    setForm(validateForm(
      {
        ...form,
        values: {
          ...form.values,
          [key]: form.values[key]?.trim(),
        },
        filled: {
          ...form.filled,
          [key]: true,
        },
      }
    ));
  }, [form,]);

  const signInOnClickHandler = useCallback(async () => {
    setIsLoading(true);
    try {
      await firebaseApp().auth().signInWithEmailAndPassword(form.values.email, form.values.password);

      // Check if login have next route, if not go to home(/)
      if (router.query.next && Object.values(NavigationPath).includes(router.query.next as NavigationPath)) {
        router.replace(router.query.next as string);
      } else {
        router.replace(NavigationPath.HOME);
      }
    } catch (error) {
      setError('Auth failure');
    } finally {
      setIsLoading(false);
    }
  }, [form.values.email, form.values.password, router,]);

  const handleKeyDown = useCallback((event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === '13' || event.key === 'Enter') {
      signInOnClickHandler();
    }
  }, [signInOnClickHandler,]);

  const renderField = (key: keyof LoginForm, label: string, props?: Partial<TextFieldProps>) => {
    return (
      <TextField
        {...props}
        error={!!form.messages[key]}
        InputLabelProps={{
          style: {
            'color': theme.palette.text.primary,
          },
        }}
        margin='dense'
        disabled={isLoading}
        fullWidth
        id={key}
        label={label}
        type={key === 'password' ? 'password' : undefined}
        value={form.values[key]}
        onChange={handleTextInputOnChange(key)}
        onBlur={handleTextInputOnBlur(key)}
        onKeyDown={handleKeyDown}
        helperText={!!form.messages[key] &&
          (form.messages[key] as ValidationMessage).type === MessageType.ERROR &&
          (form.messages[key] as ValidationMessage).message}
        variant='outlined'
        color='primary'
      />
    );
  };

  return (
    <Center>
      <StyledPaper>
        <StyledLogo src={'/images/logo.jpg'} alt={'Vigilan logo'} />
        <SignInForm>
          {error && <ErrorMessage error>{error}</ErrorMessage>}

          {renderField('email', t('email'))}
          {renderField('password', t('password'))}

          <FormControl>
            <SignInButton
              variant="contained"
              disabled={isLoading || !form.isFormValid}
              onClick={signInOnClickHandler}
            >
              {isLoading ? <CircularProgress size={20} style={{ color: 'white', marginRight: 8, }} /> : t('Sign in')}
            </SignInButton>
          </FormControl>
        </SignInForm>
      </StyledPaper>
    </Center>
  );
};

export default SignIn;